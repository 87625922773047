export interface ItemListFilter {
  page?: number;
  pageSize?: number;
  search?: string;

  //frontend control
  quizzStarted?: boolean;
  squad?: string;
  onboardingFinished?: boolean;
  knowledgeObjectiveId?: string;
  knowledgeId?: string;

  customerId?: string;
  squadUserId?: string;

  id?: string;

  muscleGroupId?: string;
  exerciseTypeId?: string;

  foodGroup?: string;
  food?: string;

  type?: string;
  lock?: boolean;
  categoryId?: string;
  advancedSearch?: { [key: string]: unknown };
  companyId?: string;
  scheduledAttendanceid?: string;
  scheduledAttendanceId?: string;
  roleId?: string;
  blackListIds?: string[];
  status?: string;
  isSpecialist?: boolean;
}
