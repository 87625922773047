import { Component, HostBinding, Input } from '@angular/core';
import { convertToBoolean } from '../../utils/util';

@Component({
  selector: 'ni-divider',
  template: ``,
  styles: [
    `
      :host {
        display: block;
        background: var(--color-divider-line-color);
        width: 100%;
        height: 1px;
        margin: 16px 0;
      }

      :host(.mini-divider) {
        width: 30px;
        background: #bababa;
      }

      @media screen and (max-width: 480px) {
        :host.po-hidden-sm {
          display: none !important;
        }
      }

      :host.vertical {
        width: 1px;
        height: 100%;
        margin: 0 16px;
        flex-shrink: 0;
        block-size: auto;
      }

      :host:not(.margin) {
        margin: 4px 0;
      }

      :host(.gray) {
        background: #bababa;
      }

      :host(.blue) {
        background: var(--color-divider-line-color);
      }


    `,
  ],
})
export class DividerComponent {
  @HostBinding('class.vertical') _vertical: boolean;
  get vertical() {
    return this._vertical;
  }

  @Input()
  set vertical(val: boolean) {
    this._vertical = convertToBoolean(val);
  }

  @HostBinding('class.margin') _margin = true;
  get margin() {
    return this._margin;
  }

  @Input()
  set margin(val: boolean) {
    this._margin = convertToBoolean(val);
  }


  @HostBinding('class.mini-divider') _miniDivider = false;
  get miniDivider() {
    return this._miniDivider;
  }

  @Input()
  set miniDivider(val: boolean) {
    this._miniDivider = convertToBoolean(val);
  }

  public classes: string;
  @HostBinding('class') get HeadingClass() {
    return this.classes;
  }

  get color(): 'gray' | 'blue' | string {
    return this._color;
  }

  @HostBinding('class') _color: 'gray' | 'blue' | string = 'blue';
  @Input()
  set color(val: 'gray' | 'blue' | string) {
    this._color = val ? val : ' ';
    this.setClasses();
  }

  @Input()
  @HostBinding('style.height')
  height: string;

  constructor() {}

  private setClasses() {
    this.classes = this._color;
  }
}
